import React, { useState, props, useEffect, useRef } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import { auth } from "services/firebaseConfig";
import { useNavigate, useParams, Link } from "react-router-dom";
import { collection, query, where, getDocs, setDoc, doc } from "firebase/firestore";
import { ref as refStorage, uploadBytes, getDownloadURL, listAll} from "firebase/storage"

import { useForm } from "react-hook-form";
import Light from "components/headers/light";
import LightFuncionaario from "components/headers/lightFuncionaario";
import Footer from "components/footers/Footer";
import { db, filesDB } from "services/firebaseConfig";
import { PDFExport, savePDF } from '@progress/kendo-react-pdf';
import { css } from "styled-components/macro"; //eslint-disable-line
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg"
import CurriculoPDF from "./CurriculoPDF";
import funcionario from "components/features/funcionario";

const Container = tw.div`relative bg-gray-200`;
const Content = tw.div`max-w-screen-xl mx-auto py-10 lg:py-10`;
const SpanVoltar = tw.span`text-gray-800 font-bold cursor-pointer`


const FormContainer = styled.div`
  ${tw`p-4 my-10 sm:p-4 md:p-4  text-gray-800  relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-xl pl-10 sm:text-xl font-normal`}
  }
     .Visualizado {
    ${tw`bg-blue-700 text-white`}
  }
    .Selecionado {
    ${tw`bg-green-700 text-white`}
  }
    .Entrevistado {
    ${tw`bg-yellow-700 text-white`}
  }
    .Desconsiderado {
    ${tw`bg-red-700 text-white`}
  }
    
  input,textarea {
    ${tw`w-full bg-transparent text-gray-600 text-base font-light tracking-wide  py-2   focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-white`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Radio = tw.input`w-auto`;
const ColumnFull = tw.div`sm:w-full flex flex-col pl-10`;
const LabelRadio = tw.label`pr-3 tracking-wide font-semibold text-sm`;
const Select = tw.select`mt-6`;

const Column = tw.div`sm:w-4/12 flex flex-col pl-10`;
const InputContainer = tw.div`relative py-4 mt-2`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 resize-none`;
const SubmitButton = tw.button`w-full relative top-0 sm:w-32  py-3 bg-gray-600 text-white rounded-full font-light tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-700 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-gray-500 fill-current w-24`
const SvgDotPattern2 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-gray-500 fill-current w-24`

export default () => {
  const {id} = useParams()
  const [usuarios, setUsuarios] = useState([]);
    const navigate = useNavigate()
    const [curriculos, setCurriculos] = useState([]);
    const [academica, setAcademica] = useState([]);
    const [experiencias, setExperiencias] = useState([]);
    const [habilidades, setHabilidades] = useState([]);
    const [cursos, setCursos] = useState([]);
    const [status, setStatus] = useState("");
    const curriculosCollectionRef = collection(db, "curriculos");
    const academicaCollectionRef = collection(db, "curriculos", id, "academica");
    const experienciasCollectionRef = collection(db, "curriculos", id, "experiencias");
    const habilidadesCollectionRef = collection(db, "curriculos", id, "habilidades");
    const cursosCollectionRef = collection(db, "curriculos", id, "cursos");
    const [pdfUrl, setPdfUrl] = useState([])
    const pdfFilesRef = refStorage(filesDB, `files/${id}/`)
    const [pdf, setPdf] = useState([]);
    const pdfCollectionRef = collection(db, "curriculos", id, "pdf");


    const [user, loading, error] = useAuthState(auth);
    const [tipo, setTipo] = useState("");

    const pdfExportComponent = useRef(null)
    const atualizarStatus = async (dataStatus) => {
      await setDoc(doc(curriculosCollectionRef, curriculos[0].useruid), {
        state: dataStatus,
       
      }, {merge:true})
      console.log(dataStatus)
  }

    const exportPDFWithComponent = () => {
      if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
      }
      };

    
    if (error) {
      return (
        <div>
          <p>Error: {error}</p>
        </div>
      );
    }
    if (!user) {
      navigate("../")     
    }
      const usersCollectionRef = collection(db, "users");
      
    useEffect(() => {
  if (user) {
    const q = query(usersCollectionRef, where("uid", "==", String(user.uid)));
        const getUsers = async () => {
            const data = await getDocs(q)
            console.log(data);
            setUsuarios(data.docs.map((doc)=> ({...doc.data(), id: doc.id})))
            
        }
        getUsers()
      }
    }, [user])
    useEffect(()=> {
      if(usuarios[0]){
        
          
          setTipo(usuarios[0].tipo)       
          
            console.log(usuarios[0].email)
          
      }
 
        
    }, [usuarios])

    
    useEffect(() => {
      const qCurriculos = query(curriculosCollectionRef, where("useruid", "==", String(id)));
        const getCurriculos = async () => {
            const data = await getDocs(qCurriculos)
            console.log(data);
            setCurriculos(data.docs.map((doc)=> ({...doc.data(), id: doc.id})))
            
        }
        const qAcademica = query(academicaCollectionRef);
        const getAcademica = async () => {
            const data = await getDocs(qAcademica)
            console.log(data);
            setAcademica(data.docs.map((doc)=> ({...doc.data(), id: doc.id})))
            
        }
        
      
      const qHabilidades = query(habilidadesCollectionRef);
      const getHabilidades = async () => {
          const data = await getDocs(qHabilidades)
          console.log(data);
          setHabilidades(data.docs.map((doc)=> ({...doc.data(), id: doc.id})))
          
      }
        const qExperiencias = query(experienciasCollectionRef);
        const getExperiencias = async () => {
            const data = await getDocs(qExperiencias)
            console.log(data);
            setExperiencias(data.docs.map((doc)=> ({...doc.data(), id: doc.id})))
            
        }
        const qCursos = query(cursosCollectionRef);
        const getCursos = async () => {
            const data = await getDocs(qCursos)
            console.log(data);
            setCursos(data.docs.map((doc)=> ({...doc.data(), id: doc.id})))
            
        }
        const qPdf = query(pdfCollectionRef);
        const getPdf = async () => {
            const data = await getDocs(qPdf)
            console.log(data);
            setPdf(data.docs.map((doc)=> ({...doc.data(), id: doc.id})))
            
        }
        const getPdfUrl = () => {
          listAll(pdfFilesRef).then((response)=>{
            response.items.forEach((item)=>{
              getDownloadURL(item).then((url)=>{
                setPdfUrl((prev)=>[...prev, url])
              })
            })
          })
        }
        getCurriculos()
        getAcademica()
        getExperiencias()
        getHabilidades()
        getCursos()
        getPdf()
        getPdfUrl()
    }, [])
    useEffect(()=> {
      
        if(curriculos[0] && usuarios[0].tipo == "funcionario"){
          setStatus(curriculos[0].state)
          console.log(curriculos[0].useruid)
          
          if(curriculos[0].state ==  undefined){
            setDoc(doc(curriculosCollectionRef, curriculos[0].useruid), {
              state: "Visualizado",
             
            }, {merge:true})
            setStatus("Visualizado")
        console.log("visto")
        }
      }
    }, [curriculos])
    useEffect(()=> {
      console.log(cursos)
  }, [cursos])
    
  
  return (<>
  {tipo != "funcionario"  &&<Light navigate={navigate} />}
      {tipo == "funcionario"  &&<LightFuncionaario navigate={navigate} />}<PDFExport ref={pdfExportComponent} paperSize="A4">
    <Container>
      <Content>
      <SpanVoltar className="spanVoltar" onClick={()=>{navigate("/home")}}>←Voltar</SpanVoltar>
               
      {curriculos.map((curri, index) => (
        <form >
        <FormContainer tw="bg-white">
          <div tw="mx-auto ">
          {tipo == "funcionario" && <TwoColumn>
                <ColumnFull>
                  <InputContainer>
                  <Label>Status</Label>
                  <Select onChange={(e)=>{setStatus(e.target.value);atualizarStatus(e.target.value)}} id="name-input">
                                     <option className={status}  disabled hidden selected >{status}</option>
                                     <option    >Visualizado</option>
                                     <option    >Selecionado</option>
                                     <option    >Entrevistado</option>
                                     <option  >Desconsiderado</option>
                                  </Select>
                  </InputContainer>
                </ColumnFull>
                
              </TwoColumn>}
          <h2 className="formTitle">Dados Pessoais</h2>
            
          
              <TwoColumn>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Nome</Label>
                    <Input  id="name-input" type="text" name="nome" value={curri.nome} disabled  />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Estado Civil</Label>
                    <Input  id="email-input" type="text" name="estadoCivil" value={curri.estadoCivil} disabled  />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="name-input">CPF</Label>
                    <Input id="name-input" type="text" name="documento" value={curri.cpf} disabled  />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="name-input">Cargo/Área de Atuação desejado</Label>
                    <Input id="name-input" type="text" name="documento" value={curri.atuacao} disabled  />
                  </InputContainer>
                  
                </Column>
                <Column>
                  
                  <InputContainer>
                    <Label htmlFor="email-input">Celular</Label>
                    <Input id="email-input"  type="text" name="celular" value={curri.celular} disabled />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">RG</Label>
                    <Input id="email-input" type="text" name="rg" value={curri.telefone} disabled  />
                  </InputContainer>
                  <InputContainer>
                  <Label>Possui CNH?</Label>
                  {curri.cnh == "true" ? (<Input  disabled id="name-input" type="text" value="Sim" name="nome" />):(<Input  disabled id="name-input" type="text" value="Não" name="nome" />)}
                  
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Data de Nascimento</Label>
                    <Input id="name-input"  type="text" name="nascimento"  value={curri.nascimento} disabled />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Telefone</Label>
                    <Input id="email-input" type="text"  name="telefone" value={curri.telefone} disabled  />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">E-mail</Label>
                    <Input id="email-input" type="text"  name="cnh" value={curri.email} disabled />
                  </InputContainer>
                </Column>
                
              </TwoColumn>
              
              {curri.cnh == "true" && 
              <TwoColumn>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Número da CNH</Label>
                    <Input  disabled id="name-input" type="text" value={curri.numCNH} name="nome" />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Vencimento da CNH</Label>
                    <Input disabled id="name-input" type="text" value={curri.vencCNH}   name="nome"  />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Categoria da CNH</Label>
                    <Input disabled id="name-input" type="text" value={curri.catCNH}   name="nome"  />
                  </InputContainer>
                </Column>
              </TwoColumn>}
              <TwoColumn>
                <ColumnFull>
                  <InputContainer>
                  <Label>Possui curso ativo de CBSP  e HUET?</Label>
                  {curri.cursoAtivo == "true" ? (<Input  disabled id="name-input" type="text" value="Sim" name="nome" />):(<Input  disabled id="name-input" type="text" value="Não" name="nome" />)}
                  </InputContainer>
                </ColumnFull>
                
              </TwoColumn>
              

              

              
            
          </div>
          
        </FormContainer>



        <FormContainer tw="bg-white">
          <div tw="mx-auto ">
            <h2 className="formTitle">Endereço</h2>
            
              <TwoColumn>
              <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Logradouro</Label>
                    <Input id="name-input"  type="text" name="rua" value={curri.rua} disabled />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Número</Label>
                    <Input id="email-input"  type="text" name="numero" value={curri.numero} disabled  />
                  </InputContainer>
                
                </Column>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Complemento</Label>
                    <Input id="name-input" type="text"  name="complemento" value={curri.complemento} disabled  />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Bairro</Label>
                    <Input id="email-input"  type="text" name="bairro" value={curri.bairro} disabled  />
                  </InputContainer>
                 
                </Column>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Cidade</Label>
                    <Input id="name-input"  type="text" name="cidade" value={curri.cidade} disabled />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input" onClick={()=>{exportPDFWithComponent()}}>Estado</Label>
                    <Input id="email-input"  type="text" name="estado" value={curri.estado} disabled />
                  </InputContainer>
                  
                </Column>
              </TwoColumn>

              
            
          </div>
          
        </FormContainer> 
        </form>
      ))}
       
      {academica.map((academica, index) => (
         <form>
         <FormContainer tw="bg-white">
           <div tw="mx-auto ">
           <h2>Formação Acadêmica {index + 1}</h2>
             
               <TwoColumn>
                 <Column>
                   <InputContainer>
                     <Label htmlFor="name-input">Curso</Label>
                     <Input value={academica.curso} disabled id="name-input" type="text" name="curso"  />
                   </InputContainer>
                   <InputContainer>
                     <Label htmlFor="name-input">Data de Término</Label>
                     <Input value={academica.termino} disabled id="name-input" type="text" name="termino"  />
                   </InputContainer>
                   </Column>
                   <Column>
                   <InputContainer>
                     <Label htmlFor="email-input">Escola</Label>
                     <Input value={academica.escola} disabled id="email-input" type="text" name="escola"  />
                   </InputContainer>
                   </Column><Column>
                   <InputContainer>
                     <Label htmlFor="name-input">Data de Início</Label>
                     <Input value={academica.inicio} disabled id="name-input" type="text" name="inicio" />
                   </InputContainer>
                 
                  
                   
                 </Column>
                
                 
               </TwoColumn>
              
           </div>
         </FormContainer>
   
         </form>
      ))}
      {experiencias.map((experiencia, index) => (
          <form >
          <FormContainer tw="bg-white">
            <div tw="mx-auto ">
            <h2>Experiência Profissional {index + 1}</h2>
              
                <TwoColumn>
                  <Column>
                    <InputContainer>
                      <Label htmlFor="name-input">Nome da Empresa</Label>
                      <Input value={experiencia.empresa} disabled id="name-input" type="text" name="empresa" />
                    </InputContainer>
                    <InputContainer>
                      <Label htmlFor="name-input">Data de Saída</Label>
                      <Input value={experiencia.saida} disabled id="name-input" type="text" name="saida" />
                    </InputContainer>
                    </Column>
                    <Column>
                    <InputContainer>
                      <Label htmlFor="email-input">Cargo</Label>
                      <Input value={experiencia.cargo} disabled id="email-input" type="text" name="cargo" />
                    </InputContainer>
                    </Column><Column>
                    <InputContainer>
                      <Label htmlFor="name-input">Data de Início</Label>
                      <Input value={experiencia.inicio} disabled id="name-input" type="text" name="inicio" />
                    </InputContainer>
                  
                    <InputContainer>
                      <Label htmlFor="name-input">Funções</Label>
                      <TextArea value={experiencia.funcao} id="message-input" disabled type="text" name="funcoes" />
                    </InputContainer>
                    
                  </Column>
                 
                  
                </TwoColumn>
  
               
              
            </div>
          </FormContainer>
  
  
  
         
            
          </form>
          
      ))}
       {habilidades.map((habilidades, index) => (
             <form >
        
        <FormContainer tw="bg-white">
          <div tw="mx-auto">
          <h2>Habilidade {index + 1}</h2>
            
              <TwoColumn>
                
                  <InputContainer>
                    
                  <TextArea id="message-input" value={habilidades.habilidades} disabled={true} type="text" name="habilidades" placeholder="Habilidades e Conhecimentos" />
                  </InputContainer>
                  
     
              </TwoColumn>
     
          </div>
          <SvgDotPattern1 />
        </FormContainer>



       
          
        </form>
       ))}
      
        {cursos.map((curso, index) => (

<form>
        

        <FormContainer tw="bg-white">
          <div tw="mx-auto">
          <h2>Cursos ou Certificação {index + 1}</h2>
            
              <TwoColumn>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Título</Label>
                    <Input  disabled id="name-input" type="text" value={curso.titulo} name="titulo"  />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="name-input">Data Final</Label>
                    <Input  disabled id="name-input" type="text" value={curso.dataFinal} name="dataFinal"  />
                  </InputContainer>
                  </Column>
                  <Column>
                  <InputContainer>
                    <Label htmlFor="email-input">Instituição</Label>
                    <Input  disabled id="email-input" type="text" value={curso.instituicao} name="instituicao"  />
                  </InputContainer>
                  </Column><Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Data de Início</Label>
                    <Input  disabled id="name-input" type="text" name="dataInicio" value={curso.dataInicio}  />
                  </InputContainer>
                
                  <InputContainer>
                    <Label htmlFor="name-input">Descrição</Label>
                    <TextArea id="message-input" disabled  type="text" value={curso.descricao} name="descricao"  />
                  </InputContainer>
                  
                </Column>
     
              </TwoColumn>

          </div>
          
        </FormContainer>

        </form>))}
      <Link to={"/curriculoPDF/" + id} target="_blank" rel="noopener noreferrer"><SpanVoltar className="spanVoltar" >+ Download PDF (Sistema)</SpanVoltar></Link><br></br>
      {(pdfUrl.length !== 0) && <a href={pdfUrl[0]} target="_blank" rel="noopener noreferrer"><SpanVoltar className="spanVoltar" >+ Download PDF (Usuário)</SpanVoltar></a>}
     
      </Content>
    </Container> </PDFExport>
    <Footer></Footer>
    </>
  );
};
