import React, { useState, props } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import uuid from "react-uuid";
import { useForm } from "react-hook-form";
import { collection, addDoc, setDoc, doc, arrayUnion } from "firebase/firestore";
import { ref, uploadBytes} from "firebase/storage"
import { db, filesDB } from "services/firebaseConfig";
import { css } from "styled-components/macro"; //eslint-disable-line
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg"
import ModalFormacao from "components/features/ModalFormacao";

const Container = tw.div`relative`;
const Message = tw.span`text-2xl sm:text-2xl py-5 `;

const Content = tw.div`max-w-screen-xl mx-auto pb-20 lg:pb-24`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 mt-20 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-2xl sm:text-2xl font-light`}
  }
  input,textarea {
    ${tw`w-full   text-base font-medium tracking-wide border-b-2 py-2   focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw``}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-4/12 flex flex-col pl-10`;
const InputContainer = tw.div`relative w-full py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input` px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const TextArea = tw.textarea`w-full text-gray-700  px-8 h-72 resize-none py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = tw.button`w-full sm:w-64 mt-6 mr-10 py-3 bg-gray-100  rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`
const SvgDotPattern2 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-secondary-500 fill-current w-24`

export default ({userUid, setCadastroExperiencia, setCadastrado2}) => {
  const { register, handleSubmit, reset } = useForm();
  const [cadastrado, setCadastrado] = useState(false);
  const pdfCollection = collection(db, "curriculos", userUid, "pdf")
  const usersCollection = collection(db, "curriculos")
  const [modal, setModal] = useState(false)
  const [file, setFile] = useState("")

  const onSubmit = async (data) => {
   
    const fileRef = ref(filesDB, `files/${userUid}/curriculoPDF`)
    uploadBytes(fileRef, file).then((snapshot) => {
        console.log(snapshot)
    });
    

    const uid = uuid();
     
    await setDoc(doc(pdfCollection, userUid), {
        pdfUid: userUid,
      
      
    })
    
    
    setModal(true) 
    console.log(file)
  }
  return (
    <>
{modal && <ModalFormacao setModal={setModal} palavra="Currículo em PDF"  setCadastrado={setCadastrado} setCadastro={setCadastroExperiencia} setCadastrado2={setCadastrado2} reset={reset}></ModalFormacao>}
    <Container>

      <Content>
        <form>
        <Message>Prezado, Faça upload de seu currículo em PDF:
</Message>
        <FormContainer tw="bg-gray-600">
          <div tw="mx-auto max-w-5xl">
          <h2>Cadastrar Currículo</h2>
            
              <TwoColumn>
                
                  <InputContainer>
                    
                  <input  id="message-input" disabled={cadastrado} onChange={(e)=>{setFile(e.target.files[0]);console.log("sdojweijf")}}  type="file" name="habilidades" placeholder="Cadastre seu currículo em PDF" />
                  </InputContainer>
                  
                
                  
                  
                
               
                
              </TwoColumn>

              
              
              {!cadastrado && (<>
              {(file!="")&&<SubmitButton tw="text-gray-900" type="button" disabled={file==""} onClick={(e)=> {e.preventDefault(); onSubmit()}}  value="Submit">Cadastrar PDF</SubmitButton>}
              <SubmitButton tw="text-gray-900" type="buttont" onClick={()=>{setCadastroExperiencia(false)}}  value="Submit">Voltar</SubmitButton>
              </>)}
              
              {cadastrado && <>
              <p><br></br>Cadastrado com sucesso!</p>
              <SubmitButton tw="text-gray-900" type="Button" onClick={()=>{setCadastroExperiencia(false)}}  value="Submit">Ok</SubmitButton>
              </>}
            
          </div>
          <SvgDotPattern1 />
        </FormContainer>



       
          
        </form>
      </Content>
    </Container>
    </>
  );
};
